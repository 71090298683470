<template>
  <div class="login-wrap">
    <!-- <div class="login"> -->
    <!-- <div class="left">
        <div class="title">两种登录模式可选:</div>
        <div class="kuang">
          <img src="@/assets/images/logo.png" alt="" />
          <span>平台账号</span>
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" /><span
            >手机号注册，操作便捷</span
          >
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" /><span
            >资产托管于平台，管理成本低</span
          >
        </div>

        <div class="kuang">
          <img src="@/assets/images/logo.png" alt="" />
          <span>Matemask <span style="color: #9f9f9f">（即将上线）</span></span>
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" />

          <span>无需注册，使用钱包地址直接登录</span>
        </div>
        <div class="items">
          <img src="@/assets/images/litttleStar.png" alt="" />
          <span>资产存于链上，交易更自由</span>
        </div>
      </div> -->
    <div class="formBox">
      <div class="formTit">
        <a class="logo" title="几人作" to="/home">
          <img src="@/assets/images/logo.png" alt="" />
          <el-link
            :underline="false"
            style="font-size: 18px; font-weight: 600; color: #fff"
            >幾人作</el-link
          >
        </a>
        <span>人工智能辅助创作平台</span>
      </div>
      <p style="color: #585858; padding: 18px">新手机号验证后将自动注册</p>
      <div class="phone">
        <el-input v-model="form.mobile" placeholder="请输入手机号">
          <template slot="prepend">+86</template>
        </el-input>
      </div>

      <div class="yanzhengma">
        <el-input
          style="width: 200px; margin-right: 2px"
          v-model="form.verifyCode"
          placeholder="请输入验证码"
          @keyup.enter.native="loginSubmit"
        ></el-input>
        <el-button
          size="small"
          round
          :disabled="code.dis"
          type="text"
          @click.prevent="getCode"
        >
          <span v-if="code.show">发送短信验证码</span>
          <span style="color: #828282; font-weight: 600" v-else
            >{{ code.count }}s后重发</span
          >
        </el-button>
      </div>
      <div class="errText" :style="`color:` + errText.color">
        {{ errText.content }}
      </div>
      <el-button
        style="width: 100%; color: #000; margin: 18px 0"
        type="primary"
        round
        @click.prevent="loginSubmit"
        >登录</el-button
      >

      <el-checkbox v-model="checked">我已仔细阅读并同意几人作</el-checkbox>
      <span class="textBut">《用户协议》</span>
      <span class="textBut">《隐私政策》</span>

      <div class="msg" v-show="noRead">
        <span>请阅读并同意协议</span>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { sendAccountVerifyCode } from "@/api/index";
import { setSm4 } from "@/utils/sm4Util";
export default {
  name: "Login",
  data() {
    return {
      //收集用户名
      form: {},
      code: {
        dis: false,
        show: true,
        timer: null, //设置计时器,
        count: "",
      },

      checked: true,
      noRead: false,
      errText: {
        color: "#585858",
        content: "",
      },
    };
  },
  methods: {
    getCode() {
      if(!this.form.mobile){
        this.errText.content = "请先输入手机号";
        this.errText.color = "#af644c";
        return
      }
      let param = {
        bussinessType:1,
        mobile: setSm4(this.form.mobile),
      };
      sendAccountVerifyCode(param).then(({ code,data, msg }) => {
        if (code == 1000&&data) {
          let TIME_COUNT = 180;
          this.errText.content = "短信验证码已发送，若未收到请耐心等待";
          this.errText.color = "#585858";
          if (!this.code.timer) {
            this.code.count = TIME_COUNT;
            this.code.show = false;
            this.code.dis = true;
            this.code.timer = setInterval(() => {
              if (this.code.count > 0 && this.code.count <= TIME_COUNT) {
                this.code.count--;
              } else {
                this.code.dis = false;
                this.code.show = true;
                clearInterval(this.code.timer);
                this.code.timer = null;
                this.errText.content = "";
              }
            }, 1000);
          }
        }
      });
    },
    async loginSubmit() {
      if (!this.checked) {
        this.noRead = true;
        setTimeout(() => {
          this.noRead = false;
        }, 1000);
        return;
      }
      if(!/^1[34578]\d{9}$/.test(this.form.mobile)){
        this.errText.content = "手机号码无效，请重新输入";
          this.errText.color = "#af644c";
      } 
      if(!this.form.mobile){
        this.errText.content = "请输入手机号码";
          this.errText.color = "#af644c";
      }
      //获取用户名|密码
      try {
        const param = {
          mobile: setSm4(this.form.mobile),
          verifyCode: this.form.verifyCode,
        };
        await this.$store.dispatch("userLogin", param);
        //判断登录的组件URL：是否有query参数【即为用户未登录时候，想去而没有去成的路由】
        let toPath = this.$route.query.redirect || "/home";
        this.$router.push(toPath);
      } catch (error) {
        this.errText.content = "验证码不正确";
        this.errText.color = "#af644c";
      }
    },
  },
  watch: {
    // "form.mobile": {
    //   //  注意：属性值发生变化后，handler执行后获取的 newVal 值和 oldVal 值是一样的
    //   handler(newVal, oldVal) {
    //     if (newVal == "") {
    //       this.errText.content = "请输入手机号码";
    //       this.errText.color = "#af644c";
    //     } else if (!/^1[34578]\d{9}$/.test(newVal)) {
    //       this.errText.content = "手机号码无效，请重新输入";
    //       this.errText.color = "#af644c";
    //     } else {
    //       this.errText.content = "";
    //     }
    //   },
    //   // 开启深度监听 deep
    //   deep: true,
    // },
  },
};
</script>

<style lang="less" scoped>
.login-wrap {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url(../../assets/images/loginbg.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  // .login {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // .left {
  //   .title {
  //     font-family: Alibaba PuHuiTi 2;
  //     font-size: 20px;
  //     color: #fffbfb77;
  //     height: 60px;
  //   }
  //   .kuang {
  //     display: flex;
  //     height: 48px;
  //     width: 190px;
  //     margin: 18px 0;
  //     align-items: center;
  //     border-radius: 20px;
  //     padding-left: 20px;
  //     background: #06070c70;
  //     color: #fffbfb;
  //     img {
  //       width: 30px;
  //       margin-right: 10px;
  //     }
  //   }
  //   .items {
  //     padding-left: 20px;
  //     height: 30px;
  //     width: 240px;
  //     display: flex;
  //     align-items: center;
  //     color: #fffbfb77;
  //     img {
  //       width: 20px;
  //       margin-right: 10px;
  //     }
  //   }
  // }
  .formBox {
    width: 454px;
    height: 420px;
    border-radius: 35px;
    position: relative;
    box-sizing: border-box;
    padding: 40px 68px;
    background: #06070c70;
    /deep/ .el-input {
      height: 48px;
      border: none;
      box-sizing: border-box;
      .el-input__inner {
        height: 48px;
        display: block;
        background: rgba(242, 242, 242, 0.1294);
        border: 1px solid #8e8e8e;
        border-radius: 24px;
      }
    }
    /deep/ .el-checkbox__inner {
      border-radius: 8px;
    }
    /deep/ .el-checkbox__label {
      color: #9f9f9f;
    }
    // /deep/ .el-checkbox__inner {
    // background-color: red;
    // }
    .phone {
      margin-bottom: 20px;
      /deep/ .el-input {
        position: relative;
        .el-input-group__prepend {
          position: absolute;
          top: 10px;
          left: -4px;
          height: 28px;
          line-height: 28px;
          background: none;
          border: none;
          border-right: 1px solid #8e8e8e;
          padding-right: 36px;
        }
        .el-input__inner {
          display: block;
          padding-left: 60px;
        }
      }
    }
    .msg {
      position: absolute;
      top: 140px;
      left: 120px;
      width: 190px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 30px;
      background: #000;
      opacity: 0.8;
      color: #ffffff;
      font-size: 16px;
    }
    .textBut {
      color: #01e49c;
      cursor: pointer;
      line-height: 22px;
      font-size: 14px;
    }
    .formTit {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 36px;
          margin-right: 10px;
        }
      }
      span {
        color: #9f9f9f;
      }
      .yanzhengma {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }
    .errText {
      height: 20px;
      line-height: 40px;
    }
  }
  // }
}
</style>